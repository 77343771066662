var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-properties"},[_c('page-title',{attrs:{"tags":_vm.getTagsByFieldKey('chat_properties')},scopedSlots:_vm._u([(_vm.$store.state.chatState.chat.limits.options.includes('chat_properties'))?{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")]),_c('a-button',{staticClass:"mt-2 md:mt-0 ml-0 md:ml-2",attrs:{"type":"primary"},on:{"click":function () {_vm.isOpen = true; _vm.isEditMode = false}}},[_vm._v(" "+_vm._s(_vm.$t('property_create'))+" ")])]},proxy:true}:null],null,true)}),(_vm.$store.state.chatState.chat.limits.options.includes('chat_properties'))?[_c('a-modal',{staticClass:"chat-properties__popup",attrs:{"title":!_vm.isEditMode ? _vm.$t('property_create') : _vm.$t('property_edit'),"ok-text":_vm.$t('pi_accept'),"ok-button-props":{
        props: {
          disabled: _vm.isOkDisabled
        }
      },"cancel-text":_vm.$t('pi_reject')},on:{"ok":_vm.handleOkClick,"cancel":_vm.onCancel},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': this,
            'key': 'selectedType',
            'prefix': 'property_',
            'options': _vm.propertyTypes,
            'multiple': false,
          }
        }},on:{"input":_vm.onSelectChange,"option:selecting":_vm.onSelect}}),(_vm.selectedType)?_c('div',{staticClass:"chat-properties__content"},[_c('text-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.propertyItem,
              'key': 'name',
              'prefix': 'property_'
            },
          }}}),(_vm.selectedType === _vm.ChatPropertyType.Date)?_c('date-picker-input',{staticClass:"w-full",attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args':{
              'model': _vm.propertyItem,
              'key': 'default_value',
              'validation': 'required',
              'clearable': false
            }
          }}}):_vm._e(),(_vm.selectedType === _vm.ChatPropertyType.Time)?_c('time-picker-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.propertyItem,
              'key': 'default_value',
              'validation': 'required',
              'clearable': false
            }
          }}}):_vm._e(),(_vm.selectedType === _vm.ChatPropertyType.DateTime)?_c('config-field',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_default_value_title')}},[_c('flat-pickr',{staticClass:"mt-4 w-full",attrs:{"config":{
              enableTime: true,
              enableSeconds: true,
              noCalendar: false,
              time_24hr: true,
            }},model:{value:(_vm.propertyItem.default_value),callback:function ($$v) {_vm.$set(_vm.propertyItem, "default_value", $$v)},expression:"propertyItem.default_value"}})],1):_vm._e(),(_vm.selectedType === _vm.ChatPropertyType.Number)?_c('number-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.propertyItem,
              'key': 'default_value',
              'validation': 'required',
              'min': -1000000000000000000,
            }
          }}}):_vm._e(),(_vm.selectedType === _vm.ChatPropertyType.Double)?_c('number-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': this,
              'key': 'doubleValueComputed',
              'step': 0.1,
              'validation': 'required',
              'min': -1000000000000000000,
            }
          }}}):_vm._e(),(_vm.selectedType === _vm.ChatPropertyType.String)?_c('text-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.propertyItem,
              'key': 'default_value',
              'validation': 'required',
            }
          }}}):_vm._e(),(_vm.selectedType === _vm.ChatPropertyType.Flag)?_c('switch-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.propertyItem,
              'key': 'default_value',
            }
          }}}):_vm._e()],1):_vm._e()],1),_c('a-table',{staticClass:"mt-5",attrs:{"dataSource":_vm.properties,"columns":_vm.columns,"pagination":{

      },"bordered":""},scopedSlots:_vm._u([{key:"type",fn:function(type){return _c('span',{},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"mr-2",domProps:{"innerHTML":_vm._s(_vm.getIcon(type))}}),_vm._v(" "+_vm._s(_vm.getPropertyType(type))+" ")])])}},{key:"actions",fn:function(text, record, index){return _c('span',{},[_c('div',{staticClass:"flex items-center"},[_c('a-icon',{staticClass:"text-warning hover:opacity-75",attrs:{"type":"edit"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.editProperty(record)}}}),_c('a-icon',{staticClass:"ml-2 text-danger hover:opacity-75",attrs:{"type":"delete"},on:{"click":function($event){return _vm.removePropertyFrontend(record, index)}}})],1)])}}],null,false,3240982058)})]:_c('div',{staticClass:"relative",staticStyle:{"height":"300px"}},[_c('not-available-options-overlay',{staticClass:"shadow",staticStyle:{"margin":"0 1rem"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }